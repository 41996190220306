import { combineReducers } from "redux";

import User from "./User";
import Time from "./Time";
import Rating from "./Rating";
import Dynamic from "./Dynamic";
import Pingpong from "./Pingpong";
import Workshop from "./Workshop";
import WorkshopNews from "./WorkshopNews";
import NetworkingStats from "./NetworkingStats";

export default combineReducers({
  User,
  Time,
  Rating,
  Dynamic,
  Pingpong,
  Workshop,
  WorkshopNews,
  NetworkingStats,
});
