import React, { ReactElement } from "react";

const Preloader = (): ReactElement => {
  return (
    <div className="cssload-container">
      <div className="cssload-loading">
        <i />
        <i />
      </div>
    </div>
  );
};

export default Preloader;
